import { Loader } from '@subComponents';
import { Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { StoryblokComponent, useStoryblok } from '@storyblok/react';
import { ContextProvider } from '@context';
import { ConfigProvider } from 'antd';
import theme from '@theme';
import ReactGA from 'react-ga4';
import Head from './Head';


const App = () => {
  const location = useLocation();
  const slug = location.pathname;

  const story = useStoryblok(slug === '/' ? 'home' : slug, { version: 'published' });

  ReactGA.send({ hitType: 'pageview', page: slug });

  //seo meta tags
  const seoData = {
    meta: story?.content?.metatags,
    // custom_tag: story?.content?.custom_tag,
    // canonical: story?.content?.canonical,
    // keywords: story?.content?.keywords,
    // language: story?.content?.language,
    // robots: story?.content?.robots
  }

  if (!story || !story.content) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <ConfigProvider theme={theme}>
        <ContextProvider xs={false} sm={false} md={false} lg={false}>
          <Head seoData={seoData} />
          <Routes>
            <Route path={slug} element={<StoryblokComponent blok={story.content} />} />
          </Routes>
        </ContextProvider>
      </ConfigProvider>
    </Suspense >
  );
};

export default App;