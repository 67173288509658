import { Col, Row } from 'antd';
import { FC } from 'react';
import { CustomButton } from '@custom';
import { useData } from '@context';

interface IWidget {
  index: number;
  widget: any;
}

const Widget: FC<IWidget> = (props: any) => {
  const { widget, index } = props;
  const { headline, sub_headline } = widget;
  const { navigateTo } = useData();

  return (
    <div className='bg_shade my_8 p_12'>
      <Row gutter={[22, 22]} align='middle' className={`${index % 2 ? 'row_reverse' : ''}`}>
        <Col xs={24} sm={24} md={12}>
          <p className='black_200 fs_xl font_secondary fw_700'>{headline}</p>
          <p className='grey_200 fs_sm font_primary lh_sm fw_400 mt_4 ta_justify'>{sub_headline}</p>
          {/* {widget?.buttons.map((btn: any) => {
            return <CustomButton className='mt_10 btn_pink' label={btn?.label} onClick={() => navigateTo(btn?.action)} key={btn._uid} />;
          })} */}
        </Col>
        <Col xs={24} sm={24} md={12}>
          <img src={widget?.icon} alt='img' className='w_100 png_sh' width={'auto'} height={'auto'} />
        </Col>
      </Row>
    </div>
  );
};

export default Widget;

