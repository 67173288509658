import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import reportWebVitals from './reportWebVitals';
import './scss/index.scss';
import { apiPlugin, storyblokInit } from '@storyblok/react';
import App from '@app';
import components from '@components';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkers from './routes/serviceWorkers';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);

storyblokInit({
  accessToken: process.env.REACT_APP_CMS_TOKEN,
  use: [apiPlugin],
  apiOptions: {
    cache: {
      type: 'memory',
      clear: 'auto',
    },
  },
  components,
  bridge: true,
});

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY || '');

root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);

reportWebVitals();
serviceWorkers.unregister();
