import { FC } from 'react'

interface IFeatureCard {
  icon: any,
  headline: string,
  subHeadline: string
}

const KeyFeature: FC<IFeatureCard> = (props) => {
  const { headline, icon, subHeadline } = props
  return (
    <div className='flex g_3'>
      <div>
        <img alt="icon" src={icon} width={'auto'} height={'auto'} />
      </div>
      <div >
        <p className='fw_700 fs_md black font_secondary fs_md'>
          {headline}
        </p>
        <p className='grey100 font_secondary fw_400 fs_sm ta_justify'>
          {subHeadline}
        </p>
      </div>
    </div>
  );
};

export default KeyFeature;