import { FC } from 'react';
import CustomButton from '../custom/CustomButton';
import { useData } from '@context';

interface IJobCard {
  job: any;
}

const JobCard: FC<IJobCard> = (props) => {
  const { job } = props;
  const { navigateTo } = useData();

  return (
    <div className='p_8 h_sh br_6 b_1_grey job_card'>
      <p className='fs_md lh_md fw_700 mt_4 mb_2 font_secondary'>{job?.role}</p>
      <div className='flex g_2 fs_sm mb_4'>
        <p>{job?.place}</p>
        <div className='b_1_grey' />
        <p className='time purple'>{job?.time}</p>
      </div>

      <p className='mt_4 fs_sm description font_secondary'>{job?.description}</p>

      <div className='flex g_2 my_4'>
        {job?.buttons.map((btn: any) => {
          return <CustomButton key={btn?._uid} label={btn?.label} onClick={() => navigateTo(btn?.action)} className='apply_btn'/>
        })}
      </div>
    </div>
  );
};

export default JobCard; 