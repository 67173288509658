import { FC } from 'react';

interface IFeatureCard {
  icon: string,
  headline: string,
  subHeadline: string,
}

const FeatureCard: FC<IFeatureCard> = (props) => {
  const { icon, headline, subHeadline } = props;

  return (
    <div className='container gp_3 flex col align_center my_4 ta_center'>
      <img src={icon} alt='feature icon' className='img_fluid p_1' width={'auto'} height={'auto'} />
      <div className='mt_1 flex col align_center'>
        <p className='black font_secondary fw_700 fs_md'>{headline}</p>
        <p className='mt_1 fw_600 fs_sm grey_200 ta_justify'>{subHeadline}</p>
      </div>
    </div>
  );
};

export default FeatureCard;