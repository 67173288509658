import { FC } from 'react';
import { useData } from '@context';

interface IResourcesSliderCard {
  item: any;
}

const ResourcesSliderCard: FC<IResourcesSliderCard> = (props) => {
  const { item } = props;
  const { setResourceType, setResourcesHeading } = useData();

  const handleSelect = (): void => {
    setResourceType(item?.type);
    setResourcesHeading(item?.sub_headline);
  }

  return (
    <div
      style={{ backgroundImage: `url(${item?.icon})`, height: 225, width: 175, backgroundSize: 'cover' }}
      className='ce_no_re_fi pointer flex align_center justify_center h_sh br_1'
      onClick={handleSelect}
    >
      <div className='card_overlay'>
        <p className='fs_md white fw_500'>{item?.headline}</p>
      </div>
    </div>
  )
}

export default ResourcesSliderCard;