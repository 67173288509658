import { Modal } from 'antd';
import { ReactElement, ReactNode, FC } from 'react';

interface CustomModalProps {
  open: boolean,
  modalBody: ReactNode | ReactElement,
  okCancel: () => void,
  footer?: ReactElement,
  title?: ReactNode | string,
  centered?: boolean,
  width?: number | string,
  className?: string
  onOk?: () => void;
}

const CustomModal: FC<CustomModalProps> = (props: CustomModalProps) => {
  const {
    open,
    modalBody,
    okCancel,
    footer = <></>,
    title = '',
    centered = false,
    width = '',
    className,
    onOk,
  } = props;

  return (
    <Modal
      className={className}
      open={open}
      title={title}
      onCancel={okCancel}
      onOk={onOk}
      footer={footer}
      maskClosable={false}
      centered={centered}
      rootClassName='modal'
      width={width}
      closable={title ? true : false}
    >
      {
        title
          ? <div className='h_1 bg_separator_grey -mx_24' />
          : ''
      }
      {
        modalBody
      }
    </Modal>
  );
};

export default CustomModal;
