import { Grid } from 'antd';
import React, { ReactNode, createContext, useContext, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Define the state type
type AppState = {
  [key: string]: string;
};

type AppAction = {
  type: string;
  payload?: any;
};

const initialState: AppState = {};

// Define the Reducer
const reducer = (state: AppState, action: AppAction): AppState => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};

// Context
export const AppContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  navigateTo: any;
  navigateWithState: any;
  resourceType: string;
  resourcesHeading: string;
  setResourceType: React.Dispatch<React.SetStateAction<string>>;
  setResourcesHeading: React.Dispatch<React.SetStateAction<string>>;
  handleRedirect: (url: string) => void;
  handleNavigate: (isExternal: boolean, route: string, path: string) => void;
} | undefined>(undefined);

// Hook to access the Context Values
export const useData = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useData must be used within an AppContextProvider');
  }
  return context;
};

interface IContextProvider {
  children: ReactNode;
  xs?: boolean; // Make these optional
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
}

// Context Provider
export const ContextProvider: React.FC<IContextProvider> = (props) => {
  const { children, xs, sm, md, lg } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  // Providing default values in case xs, sm, md, lg are undefined
  const { xs: screenXS = false, sm: screenSM = false, md: screenMD = false, lg: screenLG = false } = screens;
  const [resourceType, setResourceType] = useState<string>('blog');
  const [resourcesHeading, setResourcesHeading] = useState<string>('Explore our latest Blogs');

  const navigateTo = (route: string): void => {
    navigate(route);
    window.scroll(0, 0);
  };

  const navigateWithState = (route: string, state?: any): void => {
    navigate(route, state);
  };

  const handleRedirect = (url: string): void => {
    window.open(url, '_blank');
    window.scroll(0, 0);
  };

  const handleNavigate = (isExternal: boolean, route: string, path: string): void => {
    if (isExternal) {
      window.open(path, '_blank');
    } else {
      navigateTo(route)
    }
  }

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
        xs: xs || screenXS,
        sm: sm || screenSM,
        md: md || screenMD,
        lg: lg || screenLG,
        navigateTo,
        navigateWithState,
        resourceType,
        setResourceType,
        resourcesHeading,
        setResourcesHeading,
        handleRedirect,
        handleNavigate,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};