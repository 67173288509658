import { ChangeEvent, FC, ReactElement, FocusEvent } from 'react';
import cx from 'classnames';
import { Input } from 'antd';
import { ErrorContainer } from './index';
import CustomLabel from './CustomLabel';

interface ICustomInput {
  label?: string;
  required?: boolean;
  value?: string | number;
  name?: string;
  error?: string | undefined;
  touched?: boolean | undefined;
  className?: string;
  min?: number;
  max?: number;
  maxLength?: number;
  minLength?: number;
  disabled?: boolean;
  type?: string;
  suffixIcon?: ReactElement;
  id?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<any>) => void
  placeholder?: string;
  errorColor?: string;
}

const CustomInput: FC<ICustomInput> = (props) => {
  const {
    label,
    required,
    error,
    value,
    name,
    touched,
    className,
    disabled,
    type = 'text',
    suffixIcon = <></>,
    id = '',
    onChange,
    onBlur,
    placeholder = '',
    maxLength = Infinity,
    errorColor = 'white',
  } = props;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (type === 'number' && e.target.value.length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength);
    }
    onChange?.(e);
  };

  return (
    <>
      <CustomLabel label={label} required={required} />
      <Input
        id={id}
        className={cx('p_3 br_0', className)}
        name={name}
        value={value}
        onChange={handleInputChange}
        type={type}
        disabled={disabled}
        suffix={suffixIcon}
        placeholder={placeholder}
        onBlur={onBlur} // Pass the onBlur prop directly
      />
      <ErrorContainer touched={touched} error={error} color={errorColor} />
    </>
  );
};

export default CustomInput;