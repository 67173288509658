import { lazy } from 'react';

const Page = lazy(() => import('@root/Page'));
const Header = lazy(() => import('@common/Header'));
const Footer = lazy(() => import('@common/Footer'));
const HeroBanner = lazy(() => import('@sections/HeroBanner'));
const QuoteContainer = lazy(() => import('@sections/QuoteContainer'));
const OurProducts = lazy(() => import('@sections/OurProducts'));
const ProductAd = lazy(() => import('@sections/ProductAd'));
const OurPartners = lazy(() => import('@sections/OurPartners'));
const ImgBanner = lazy(() => import('@sections/ImgBanner'));
const JoinOurTeam = lazy(() => import('@sections/JoinOurTeam'));
const GetInTouchForm = lazy(() => import('@forms/GetInTouchForm'));
const NewsletterForm = lazy(() => import('@forms/NewsletterForm'));
const ProductBanner = lazy(() => import('@sections/ProductBanner'));
const MotionBanner = lazy(() => import('@sections/MotionBanner'));
const HowItWorks = lazy(() => import('@sections/HowItWorks'));
const ImageContainer = lazy(() => import('@sections/ImageContainer'));
const BusinessNeeds = lazy(() => import('@sections/BusinessNeeds'));
const FaqPanel = lazy(() => import('@sections/FaqPanel'));
const ResourcesSlider = lazy(() => import('@sections/ResourcesSlider'));
const ResourcesPanel = lazy(() => import('@sections/ResourcesPanel'));
const Blog = lazy(() => import('@sections/Blog'));
const Description = lazy(() => import('@sections/Description'));
const PointsSection = lazy(() => import('@sections/PointsSection'));
const ValuesSection = lazy(() => import('@sections/ValuesSection'));
const JobsGrid = lazy(() => import('@sections/JobsGrid'));
const JobApplication = lazy(() => import('@sections/JobApplication'));
const WhatInside = lazy(() => import('@sections/WhatInside'));
const ProjectsGrid = lazy(() => import('@sections/ProjectsGrid'));
const ScreenshotsCarousel = lazy(() => import('@sections/ScreenshotsCarousel'));
const OurTeam = lazy(() => import('@sections/OurTeam'));
const ResponsiveImage = lazy(() => import('@sections/ResponsiveImage'));
const HorizontalPoints = lazy(() => import('@sections/HorizontalPoints'));
const PrivacyPolicy = lazy(() => import('@sections/PrivacyPolicy'));
const CookiesPolicy = lazy(() => import('../components/modals/CookiesPolicy'));

export {
  Blog, BusinessNeeds, Description, FaqPanel, Footer, GetInTouchForm, Header, HeroBanner, HorizontalPoints, HowItWorks,
  ImageContainer, ImgBanner, JobApplication, JobsGrid, JoinOurTeam, MotionBanner, NewsletterForm, OurPartners, OurProducts, OurTeam, Page, PointsSection, ProductAd, ProductBanner, ProjectsGrid, QuoteContainer, ResourcesPanel, ResourcesSlider, ResponsiveImage, ScreenshotsCarousel, ValuesSection, WhatInside, PrivacyPolicy, CookiesPolicy,
};

