import React, { FC } from 'react';
import '../../scss/styles/flip_card.scss'; // Import your SCSS styles
import { useData } from '@context';

interface ITemaMemberCard {
  memberData: any;
  instagramIcon: string;
  linkedInIcon: string;
}

const TeamMemberCard: FC<ITemaMemberCard> = (props) => {
  const { memberData, linkedInIcon, instagramIcon } = props;
  const { handleRedirect } = useData();

  const frontStyle = {
    backgroundImage: `linear-gradient(0deg, rgb(0, 0, 0) 0%, transparent 55%), url(${memberData?.image})`,
  };

  return (
    <div className="flip-card br-3">
      <div className="flip-card-inner">
        <div className="flip-card-front flex" style={frontStyle}>
          <div className='white p_4 align_self_end'>
            <p className='fs_md font_secondary fw_700'>{memberData?.name}</p>
            <p className='fs_xs fw_800'>{memberData?.position}</p>
            <p className='fs_xs'>{memberData?.description}</p>
          </div>
        </div>
        <div className="flip-card-back">
          <p className='l_spacing pointer fs_sm'>{memberData?.follow_label}</p>
          <div className='flex g_2'>
            <img
              src={linkedInIcon}
              alt='LinkedIn'
              width={50}
              height={'auto'}
              className='pointer'
              onClick={() => handleRedirect(memberData?.linkedin)}
            />
            <img
              src={instagramIcon}
              alt='Instagram'
              width={50}
              height={'auto'}
              className='pointer'
              onClick={() => handleRedirect(memberData?.instagram)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamMemberCard;