import { FC } from 'react';

interface IPointCard {
  obj: any;
}

const PointCard: FC<IPointCard> = (props) => {
  const { obj } = props;

  return (
    <div className="w_200 bg_white p_4 br_1">
      <b className="font_600 fs_lg mb_4">{obj?.headline}</b>
      <p>{obj?.description}</p>
    </div>
  )
}

export default PointCard;