import { FC } from 'react';

interface IWhatInsideFeatureCard {
  icon: string;
  headline: string;
  description: string;
}

const WhatInsideFeatureCard: FC<IWhatInsideFeatureCard> = (props) => {
  const { icon, headline, description } = props;

  return (
    <div className='bg_white h_sh br_3 p_8'>
      <img src={icon} alt='feature icon' width={'50px'} height={'auto'} />
      <p className='fs_md fw_700 mt_4 primary font_secondary'>{headline}</p>
      <p className='fs_sm fw_400 mt_4'>{description}</p>
    </div>
  )
}

export default WhatInsideFeatureCard;