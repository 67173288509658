import { Card } from 'antd';
import { ReactElement } from 'react';

interface CustomCardProps {
  title?: ReactElement | string,
  cardBody?: ReactElement,
  className?: string,
  bodyClassName?: object,
  onClick?: () => void,
  width?: number;
  height?: number;
}
const CustomCard = (props: CustomCardProps) => {
  const {
    cardBody,
    title = '',
    className = '',
    bodyClassName,
    onClick,
    height,
    width,
  } = props;

  const style = {
    width,
    height,
  }

  return (
    <Card
      title={title}
      className={className}
      bodyStyle={bodyClassName}
      onClick={onClick}
      style={style}
    >
      {cardBody}
    </Card>
  );
};

export default CustomCard;
