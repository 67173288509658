import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { useData } from '@context';

interface IProductCard {
  img: string,
  headline: string,
  sub_headline: string,
  action: string,
}

const ProductCard: FC<IProductCard> = (props) => {
  const { img, headline, sub_headline, action } = props;
  const { navigateTo } = useData();

  return (
    <div role='blutton' onClick={() => navigateTo(action)}>
      <div style={{ backgroundImage: `url(${img})`, height: 420 }} className='ce_no_re_fi' />
      <div className='flex justify_between align_center py_4 t_tr h_6r g_1 ellipses'>
        <div>
          <p className='fs_md mt_4 fw_800 font_secondary'>{headline}</p>
          <p className='fs_s'>{sub_headline}</p>
        </div>
        <div className='white pointer'>
          <RightOutlined className='h_product_btn' />
        </div>
      </div>
    </div>
  );
};

export default ProductCard;