import { Segmented } from 'antd';
import { FC } from 'react';
import { useData } from '../../context/DataContext';

interface CustomSegmentedProps {
  options: string[],
  className: string,
  size?: 'small' | 'middle',
}

const CustomSegmented: FC<CustomSegmentedProps> = (props) => {
  const { xs } = useData();
  const {
    options,
    className = '',
    size = xs ? 'small' : 'middle',
  } = props;
  return (
    <Segmented
      options={options}
      className={className}
      size={size}
    />
  );
};

export default CustomSegmented;