import { FC } from 'react';
import * as DOMPurify from 'dompurify';

interface IRoleDescription {
  headline: string;
  innerHTML: string;
}

const RoleDescription: FC<IRoleDescription> = (props) => {
  const { headline, innerHTML } = props;
  const sanitizedHTML = DOMPurify.sanitize(innerHTML);

  return (
    <div className='p_2'>
      <p className='my_2 fs_md fw_700 font_secondary'>{headline}</p>
      <div className='bt_1_grey mb_3'/>
      <div className='default_markup' dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
    </div>
  )
}

export default RoleDescription;