import { FC, Dispatch, SetStateAction } from 'react';

interface IFaqCard {
  question: string;
  answer: string;
  setCollapseKey: Dispatch<SetStateAction<string>>;
  collapseKey: string;
  faqIndex: string;
  openIcon: string;
  closeIcon: string;
}

const FaqCard: FC<IFaqCard> = (props) => {
  const { question, answer, setCollapseKey, collapseKey, faqIndex, openIcon, closeIcon } = props;

  const toggle = () => {
    if (collapseKey !== (faqIndex)) {
      setCollapseKey(faqIndex);
    } else {
      setCollapseKey('');
    }
  };

  return (
    <>
      <div className='flex justify_between'>
        <p className='fs_sm primary fw_500'>{question}</p>
        {
          collapseKey === faqIndex ? <img onClick={toggle} className='pointer fs_xxs' src={closeIcon} /> : <img onClick={toggle} className='pointer fs_xxs' src={openIcon} />
        }
      </div>
      {(collapseKey === (faqIndex)) && <>{answer}</>}
    </>
  );
};

export default FaqCard;