import { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

interface HeadProps {
  seoData: any;
}

const Head: FC<HeadProps> = ({ seoData }) => {
  const metaData = seoData?.meta;
  return (
    <HelmetProvider>
      <Helmet>
        <title>{metaData?.title}</title>
        <meta name="title" content={metaData?.title} />
        <meta name="description" content={metaData?.description} />
        <meta name="twitter:title" content={metaData?.twitter_title} />
        <meta
          name="twitter:description"
          content={metaData?.twitter_description}
        />
        <meta name="twitter:image" content={metaData?.twitter_image} />
        <meta name="og:title" content={metaData?.og_title} />
        <meta name="og:description" content={metaData?.og_description} />
        <meta name="og:image" content={metaData?.og_image} />
      </Helmet>
    </HelmetProvider>
  );
}

export default Head;
