import { FC } from 'react';
import { CustomCard } from '@custom';
import { useData } from '@context';

interface IProjectCard {
  obj: any;
}

const ProjectCard: FC<IProjectCard> = (props) => {
  const { obj } = props;
  const { navigateTo } = useData();

  return (
    <>
      <CustomCard
        height={400}
        className='h_sh_md'
        cardBody={
          <div className='ta_center felx col'>
            {/* <img src={obj?.image} alt='project' className='png_sh br_2' height={260} width={'100%'} /> */}
            <div style={{ backgroundImage: `url(${obj?.image})`, height: 250 }} className='br_2 ce_no_re_fi' />
            <div className='mt_2'>
              <p className='fs_md lh_sm fw_700 font_secondary'>{obj?.headline}</p>
              <p className='grey_200'>{obj?.sub_headline}</p>
            </div>
            <div>
              <p onClick={() => navigateTo(obj?.buttons[0]?.action)} className='pointer fs_xs primary'>
                {obj?.buttons[0]?.label ? obj?.buttons[0]?.label : ''}
              </p>
            </div>
          </div>
        }
      />
    </>
  )
}

export default ProjectCard;