import { Collapse } from 'antd';
import { ExpandIconPosition } from 'antd/es/collapse/Collapse';
import { CollapsibleType } from 'antd/es/collapse/CollapsePanel';
import { ReactElement } from 'react';

const { Panel } = Collapse;

export interface IPanelObject {
  header: string,
  key: string,
  body: ReactElement | string,
  collapsible?: CollapsibleType,
}

interface CustomCollapseProps {
  panelList: IPanelObject[],
  panelStyle?: object,
  bordered?: boolean,
  accordion?: boolean,
  expandIconPosition?: ExpandIconPosition,
  className?: string,
  defualtActiveKey?: string,
  onChange?: () => void,
}

const CustomCollapse = (props: CustomCollapseProps) => {
  const {
    panelList,
    panelStyle = {},
    bordered = false,
    accordion = false,
    expandIconPosition = 'end',
    className = '',
    defualtActiveKey = '0',
    onChange,
  } = props;
  return (
    <Collapse
      bordered={bordered}
      accordion={accordion}
      expandIconPosition={expandIconPosition}
      className={className}
      onChange={onChange}
      defaultActiveKey={defualtActiveKey}
    >
      {
        panelList?.map((item: IPanelObject) => (
          <Panel header={item.header} key={item.key} style={panelStyle} collapsible={item?.collapsible}>
            {item.body}
          </Panel>
        ))
      }
    </Collapse>);
};

export default CustomCollapse;
