import { FC } from 'react';

interface ErrorContainerProps {
  touched: boolean | undefined,
  error: string | undefined,
  color?: string
}

const ErrorContainer: FC<ErrorContainerProps> = (props: ErrorContainerProps) => {
  const { touched, error, color = 'white' } = props;

  return (
    <>
      {
        touched && error
          ? <span className={`fs_12 lh_12 font_primary fw_400 ${color}`}>{error}</span>
          : <></>
      }
    </>
  );
};

export default ErrorContainer;