import { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    borderRadius: 4,
    colorBgBase: '#FCFBFF',
    fontFamily: '\'Red Hat Display\',sans-serif',
  },
};

export default theme;