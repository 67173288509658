import { FC, useState, useLayoutEffect } from 'react';
import { Spin, Space } from 'antd';
import { loader } from '@assets';
import { AnimatedIcon } from '@subComponents';

const Loader: FC = () => {
  const [dots, setDots] = useState('');

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length === 3 ? '' : prevDots + '.'));
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='h_100vh w_100 center'>
      <Space size='large' direction='vertical' align='center'>
        {/* <Spin spinning size='large' /> */}
        {AnimatedIcon(loader)}
        <p>Loading{dots}</p>
      </Space>
    </div>
  );
};

export default Loader;