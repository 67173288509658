import { useData } from '@context';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface ILocationCard {
  locationIcon: string,
  place: string,
  address: string,
  arrow?: string,
  action: string,
}

const LoacationCard: FC<ILocationCard> = (props) => {
  const { locationIcon, place, address, arrow, action } = props;
  const { navigateTo, lg } = useData();

  return (
    <address role='button' className='pointer flex justify_center g_3 align_center' onClick={() => navigateTo(action)}>
      <div className={`flex align_center ${lg ? '' : 'col'}`}>
        <img src={locationIcon} alt='location icon' height={'80%'} width={'auto'} />
        <p className='fw_sm primary'>{place}</p>
      </div>
      <p>{address}</p>
      {arrow && <img src={arrow} alt='arrow icon' height={20} width={'auto'} />}
    </address>
  );
};

export default LoacationCard;