import {
  Blog,
  BusinessNeeds,
  Description,
  FaqPanel,
  Footer,
  GetInTouchForm,
  Header,
  HeroBanner,
  HorizontalPoints,
  HowItWorks,
  ImageContainer,
  ImgBanner,
  JobApplication,
  JobsGrid,
  JoinOurTeam,
  MotionBanner,
  NewsletterForm,
  OurPartners,
  OurProducts,
  OurTeam,
  Page,
  PointsSection,
  ProductAd,
  ProductBanner,
  ProjectsGrid,
  QuoteContainer,
  ResourcesPanel,
  ResourcesSlider,
  ResponsiveImage,
  ScreenshotsCarousel,
  ValuesSection,
  WhatInside,
  PrivacyPolicy,
  CookiesPolicy,
} from '@lazy';

// CMS Component Key's are should be in CamelCase
// CMS Object keys shold be in snakecase
// CMS routs should be in Kebab Case(Spinal Case)

const components = {
  page: Page,
  header: Header,
  footer: Footer,
  heroBanner: HeroBanner,
  quoteContainer: QuoteContainer,
  ourProducts: OurProducts,
  productAd: ProductAd,
  ourPartners: OurPartners,
  imgBanner: ImgBanner,
  joinOurTeam: JoinOurTeam,
  getInTouchForm: GetInTouchForm,
  newsletterForm: NewsletterForm,
  productBanner: ProductBanner,
  howItWorks: HowItWorks,
  imageContainer: ImageContainer,
  businessNeeds: BusinessNeeds,
  motionBanner: MotionBanner,
  faqPanel: FaqPanel,
  resourcesSlider: ResourcesSlider,
  resourcesPanel: ResourcesPanel,
  blog: Blog,
  description: Description,
  pointsSection: PointsSection,
  valuesSection: ValuesSection,
  jobsGrid: JobsGrid,
  jobApplication: JobApplication,
  whatInside: WhatInside,
  projectsGrid: ProjectsGrid,
  screenshotsCarousel: ScreenshotsCarousel,
  ourTeam: OurTeam,
  responsiveImage: ResponsiveImage,
  horizontalPoints: HorizontalPoints,
  privacyPolicy: PrivacyPolicy,
  cookies: CookiesPolicy,
};

export default components;