import { FC } from 'react';

interface IValueCard {
  icon: string;
  headline: string;
  description: string;
}

const ValueCard: FC<IValueCard> = (props) => {
  const { icon, headline, description } = props;

  return (
    <div className='p_12 b_1_grey h_sh br_6 flex col g_6 align_start'>
      <img src={icon} alt='icon' height={100} width={'auto'} />
      <p className='fs_lg font_secondary fw_700'>{headline}</p>
      <p className='fs_sm lh_sm h_4r'>{description}</p>
    </div >
  );
};

export default ValueCard;