import { FC, ChangeEventHandler, FocusEventHandler } from 'react';
import cx from 'classnames';
import TextArea from 'antd/es/input/TextArea';
import { ErrorContainer } from './index';
import CustomLabel from './CustomLabel';

interface CustomTextAreaProps {
  label?: string,
  required?: boolean,
  error?: string,
  value?: string | number,
  name?: string,
  touched?: boolean,
  className?: string,
  maxLength?: number,
  minLength?: number,
  disabled?: boolean,
  onChange?: ChangeEventHandler<HTMLTextAreaElement> | undefined,
  onBlur?: FocusEventHandler<HTMLTextAreaElement>,
  showCount?: boolean,
  placeholder?: string,
}

const CustomTextArea: FC<CustomTextAreaProps> = (props: CustomTextAreaProps) => {

  const {
    label = '',
    required = false,
    error = '',
    value = '',
    name = '',
    touched = false,
    className = '',
    maxLength = 0,
    minLength = 0,
    disabled = false,
    onChange = () => null,
    showCount,
    placeholder,
  } = props;

  return (
    <>
      <CustomLabel
        label={label}
        required={required}
      />
      <TextArea
        className={cx('fs_16 lh_20 charcoal br_0', className)}
        name={name}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        minLength={minLength}
        disabled={disabled}
        showCount={showCount}
        placeholder={placeholder}
      />
      <ErrorContainer touched={touched} error={error} />
    </>
  );
};

export default CustomTextArea;
