interface CustomLabelProps {
  label: string | undefined,
  required: boolean | undefined,
}

const CustomLabel = (props: CustomLabelProps) => {
  const { label, required } = props;
  return <p className="font_primary fs_sm lh_s fw_600 mb_1">{required ? <>{label}<span className="danger">*</span></> : label}</p>;
};

export default CustomLabel;
