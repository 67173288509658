import { Button } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ReactElement } from 'react';

interface ICustomButton {
  label?: string,
  disabled?: boolean,
  className?: string,
  shape?: 'circle' | 'default' | 'round' | undefined,
  size?: SizeType,
  icon?: ReactElement | string,
  loading?: boolean,
  onClick?: () => void,
  htmlType?: 'submit' | 'button' | 'reset' | undefined
  iconPosition?: string,
  name?: string,
  type?: 'default' | 'primary' | 'link' | 'text' | 'ghost' | 'dashed' | undefined,
}

const CustomButton = (props: ICustomButton) => {
  const {
    label = '',
    className = '',
    disabled = false,
    shape = 'default',
    icon = '',
    size = 'middle',
    loading = false,
    onClick = () => null,
    htmlType = 'submit',
    iconPosition = 'left',
    type = 'primary',
  } = props;

  const iconUi = typeof icon === 'string' && icon ? <img src={icon} alt="Icon" /> : icon;

  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={onClick}
      shape={shape}
      size={size}
      loading={loading}
      type={type}
      htmlType={htmlType}
    >
      {iconPosition === 'left' && icon && <span>{iconUi}</span>}
      {label}
      {iconPosition === 'right' && icon && <span>{iconUi}</span>}
    </Button>
  );
};

export default CustomButton;
