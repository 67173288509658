import { useData } from '@context';
import { CustomCard } from '@custom';
import { Link } from 'react-router-dom';

const ResourceTypeCard = ({ data }: any) => {
  const { navigateTo, handleNavigate } = useData();

  return (
    <CustomCard
      className='h_sh_md'
      onClick={() => handleNavigate(data?.external_link, data?.buttons[0].action, data?.url)}
      height={420}
      width={280}
      cardBody={
        <div>
          <div className='mb_3 ce_no_re_fi' style={{ backgroundImage: `url(${data?.preview_image})`, height: 150 }} />
          {(data.author && data.date) && <div className='flex'><p>{data?.date}</p> <div className='horizontal_hr' /> <p>{data?.author}</p></div>}
          <p className='fs_sm fw_700  font_secondary'>{data?.preview_headline}</p>
          <p className='mt_2'>{data?.preview_sub_headline}</p>
          {data.linkType ?
            <Link to={data?.url} type='_blank' className='primary pointer mt_2 fw_500'>{data?.link_label}</Link>
            : <p className='primary pointer mt_2 fw_500' onClick={() => handleNavigate(data?.external_link, data?.buttons[0].action, data?.url)}>{data?.buttons[0].label}</p>
          }
        </div>
      }
    />
  );
};

export default ResourceTypeCard;
